<template>
  <section class="page-home-projects p-top-3 p-bot-3">
    <!-- Title -->
    <div class="row-1 flex --justify-space-between --align-center">
      <h2 class="title-m m-right-16px flex-1" v-anim-reveal-text>
        {{ $t('pages.home.projectTitle') }}
      </h2>

      <WidgetLinkMainUnderline
        :link="{ key: 'view-all', route: { name: 'projects' } }"
      />
    </div>
    <div
      class="m-top-32px text-l page-home-projects__swiper"
      v-anim-parallax-element="{
        target: 'picture',
        offset: 10,
      }"
    >
      <WidgetSliderSwiper :slides="formattedProjects" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'PagesHomeProjects',
}
</script>

<script setup>
const props = defineProps({
  projects: Object,
})

const formattedProjects = computed(() => {
  return props.projects.map((project) => ({
    title: project.title,
    cover: project.cover?.[0],
    route: {
      name: 'projects-slug',
      params: { slug: project.slug },
    },
  }))
})
</script>

<style lang="scss">
@import './style.scss';
</style>
